.landing-body {
  /*background-image: url("../background-image.jpg");*/
  background-image: linear-gradient(#051937, #0a0e02);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.landingPage-avatar {
  border-radius: 50%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 10%;
  height: 10%;
}

.landingPage-heading {
  color: whitesmoke;
}

.landingPage-name {
  color: whitesmoke;
  font-size: 58px;
  font-weight: 400;
}
.experiencePage-name {
  color: rgb(10, 10, 10);
  font-size: 48px;
  font-weight: 600;
}

.landingPage-subheading {
  color: white;
  margin: auto;
  font-size: x-large;
  font-weight: 500;
}

.experiencePage-subheading {
  color: rgb(53, 48, 48);
  margin: auto;
  font-size: x-large;
  font-weight: 500;
}
.rounded-social-buttons .circle-button {
  display: inline-block;
  position: relative;
  cursor: pointer;
  width: 3.125rem;
  height: 3.125rem;
  border: 0.125rem solid transparent;
  padding: 0;
  text-decoration: none;
  text-align: center;
  color: #fefefe;
  font-size: 1.5625rem;
  font-weight: normal;
  line-height: 2em;
  border-radius: 1.6875rem;
  transition: 0.5s ease all;
  margin-right: 0.25rem;
}
.rounded-social-buttons .circle-button:hover,
.rounded-social-buttons .circle-button:focus {
  transform: rotate(360deg);
}
.rounded-social-buttons .circle-button.facebook {
  background: #3b5998;
}
.rounded-social-buttons .circle-button.facebook:before {
  font-family: 'FontAwesome';
  content: '';
}
.rounded-social-buttons .circle-button.facebook:hover,
.rounded-social-buttons .circle-button.facebook:focus {
  color: #3b5998;
  background: #fefefe;
  border-color: #3b5998;
}
.rounded-social-buttons .circle-button.twitter {
  background: #55acee;
}
.rounded-social-buttons .circle-button.twitter:before {
  font-family: 'FontAwesome';
  content: '';
}
.rounded-social-buttons .circle-button.twitter:hover,
.rounded-social-buttons .circle-button.twitter:focus {
  color: #55acee;
  background: #fefefe;
  border-color: #55acee;
}
.rounded-social-buttons .circle-button.linkedin {
  background: #007bb5;
}
.rounded-social-buttons .circle-button.linkedin:before {
  font-family: 'FontAwesome';
  content: '';
}
.rounded-social-buttons .circle-button.linkedin:hover,
.rounded-social-buttons .circle-button.linkedin:focus {
  color: #007bb5;
  background: #fefefe;
  border-color: #007bb5;
}
.rounded-social-buttons .circle-button.youtube {
  background: #bb0000;
}
.rounded-social-buttons .circle-button.youtube:before {
  font-family: 'FontAwesome';
  content: '';
}
.rounded-social-buttons .circle-button.youtube:hover,
.rounded-social-buttons .circle-button.youtube:focus {
  color: #bb0000;
  background: #fefefe;
  border-color: #bb0000;
}
.rounded-social-buttons .circle-button.instagram {
  background: #125688;
}
.rounded-social-buttons .circle-button.instagram:before {
  font-family: 'FontAwesome';
  content: '';
}
.rounded-social-buttons .circle-button.instagram:hover,
.rounded-social-buttons .circle-button.instagram:focus {
  color: #125688;
  background: #fefefe;
  border-color: #125688;
}
.rounded-social-buttons .circle-button.pinterest {
  background: #cb2027;
}
.rounded-social-buttons .circle-button.pinterest:before {
  font-family: 'FontAwesome';
  content: '';
}
.rounded-social-buttons .circle-button.pinterest:hover,
.rounded-social-buttons .circle-button.pinterest:focus {
  color: #cb2027;
  background: #fefefe;
  border-color: #cb2027;
}
.rounded-social-buttons .circle-button.google-plus {
  background: #dd4b39;
}
.rounded-social-buttons .circle-button.google-plus:before {
  font-family: 'FontAwesome';
  content: '';
}
.rounded-social-buttons .circle-button.google-plus:hover,
.rounded-social-buttons .circle-button.google-plus:focus {
  color: #dd4b39;
  background: #fefefe;
  border-color: #dd4b39;
}
.rounded-social-buttons .circle-button.github {
  background: #000000;
}
.rounded-social-buttons .circle-button.github:before {
  font-family: 'FontAwesome';
  content: '';
}
.rounded-social-buttons .circle-button.github:hover,
.rounded-social-buttons .circle-button.github:focus {
  color: #000000;
  background: #fefefe;
  border-color: #000000;
}
.rounded-social-buttons .circle-button.tumblr {
  background: #32506d;
}
.rounded-social-buttons .circle-button.tumblr:before {
  font-family: 'FontAwesome';
  content: '';
}
.rounded-social-buttons .circle-button.tumblr:hover,
.rounded-social-buttons .circle-button.tumblr:focus {
  color: #32506d;
  background: #fefefe;
  border-color: #32506d;
}

.typedContent {
  color: whitesmoke;
  font-weight: bolder;
  font-size: xx-large;
}

.avatar {
  padding-top: 6rem;
}

/* Footer section */
ul {
  margin: 0px;
  padding: 0px;
}
.footer-section {
  background: #151414;
  position: relative;
}
.footer-cta {
  border-bottom: 1px solid #373636;
}
.single-cta i {
  color: #f5aa09;
  font-size: 30px;
  float: left;
  margin-top: 8px;
}
.cta-text {
  padding-left: 15px;
  display: inline-block;
}
.cta-text h4 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 2px;
}
.cta-text span {
  color: #757575;
  font-size: 15px;
}
.footer-content {
  position: relative;
  z-index: 2;
}
.footer-pattern img {
  position: absolute;
  top: 0;
  left: 0;
  height: 330px;
  background-size: cover;
  background-position: 100% 100%;
}

.footer-logo img {
  max-width: 200px;
}
.footer-text p {
  margin-bottom: 14px;
  font-size: 14px;
  color: #7e7e7e;
  line-height: 28px;
}
.footer-social-icon span {
  color: #fff;
  display: block;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 20px;
}

.footer-widget-heading h3 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 40px;
  position: relative;
}
.footer-widget-heading h3::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: -15px;
  height: 2px;
  width: 50px;
  background: #f5aa09;
}
.footer-widget ul li {
  display: inline-block;
  /* float: left; */
  width: 50%;
  margin-bottom: 12px;
}
.footer-widget ul li a:hover {
  color: #f5aa09;
}
.footer-widget ul li a {
  color: #878787;
  text-transform: capitalize;
}
.subscribe-form {
  position: relative;
  overflow: hidden;
}
.subscribe-form input {
  width: 100%;
  padding: 14px 28px;
  background: #2e2e2e;
  border: 1px solid #2e2e2e;
  color: #fff;
}
.subscribe-form button {
  position: absolute;
  right: 0;
  background: #f5aa09;
  padding: 13px 20px;
  border: 1px solid #f5aa09;
  top: 0;
}
.subscribe-form button i {
  color: #fff;
  font-size: 22px;
  transform: rotate(-6deg);
}
.copyright-area {
  background: #202020;
  padding: 10px 0;
}
.copyright-text p {
  margin: 0;
  font-size: 14px;
  color: #878787;
}
.copyright-text p a {
  color: whitesmoke;
}
.footer-menu li {
  display: inline-block;
  margin-left: 20px;
}
.footer-menu li:hover a {
  color: #f5aa09;
}
.footer-menu li a {
  font-size: 14px;
  color: #878787;
}

.aboutAvatar {
  border-radius: 50%;
  max-width: 20rem;
  max-height: 20rem;
}
.timeline {
  position: relative;
  width: 800px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.timeline:before {
  content: '';
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 2px;
  height: 100%;
  background-color: #ccc;
}
.timeline ul {
  list-style-type: none;
}
.timeline ul li {
  width: 50%;
  padding: 5px 30px 40px 30px;
  cursor: pointer;
}
.timeline ul li:nth-child(even):before {
  content: '';
  position: absolute;
  width: 12px;
  height: 12px;
  background-color: #0fb9b1;
  border-radius: 50%;
  left: -6px;
  top: 12px;
  transition: 0.5s;
}
.timeline ul li:nth-child(odd):before {
  content: '';
  position: absolute;
  width: 12px;
  height: 12px;
  background-color: #0fb9b1;
  border-radius: 50%;
  right: -6px;
  top: 12px;
  transition: 0.5s;
}
.timeline ul li:hover:before {
  box-shadow: 0px 0px 5px 2px #0fb9b1;
}
.timeline ul li:nth-child(even) {
  float: right;
  text-align: left;
  clear: both;
}
.timeline ul li:nth-child(odd) {
  float: left;
  text-align: right;
  clear: both;
}
.timeline ul li:nth-child(odd) .time {
  position: absolute;
  right: -38%;
  top: 3px;
  background-color: #0fb9b1;
  color: #fff;
  text-align: left;
  padding-left: 10px;
  width: 120px;
  height: 28px;
  line-height: 28px;
  border-radius: 20px;
  box-shadow: 0px 0px 0px 3px rgba(15, 185, 177, 0.3);
}
.timeline ul li:nth-child(even) .time {
  position: absolute;
  left: -38%;
  top: 3px;
  background-color: #0fb9b1;
  color: #fff;
  text-align: right;
  padding-right: 10px;
  width: 120px;
  height: 28px;
  line-height: 28px;
  border-radius: 20px;
  box-shadow: 0px 0px 0px 3px rgba(15, 185, 177, 0.3);
}
.timeline ul li .content h3 {
  color: #218c74;
  font-size: 17px;
}
.timeline ul li .content p {
  color: #222;
}

@media (max-width: 750px) {
  .timeline {
    width: 100%;
    margin: 5px 0px;
  }
  .timeline:before {
    left: 20px;
  }
  .timeline ul li {
    width: 100%;
    padding: 3px 0px 40px 20px;
    cursor: pointer;
  }
  .timeline ul li:nth-child(even):before {
    left: -6px;
  }
  .timeline ul li:nth-child(odd):before {
    left: -6px;
  }
  .timeline ul li:nth-child(even) {
    float: left;
    text-align: left;
  }
  .timeline ul li:nth-child(odd) {
    float: left;
    text-align: left;
  }
  .timeline ul li:nth-child(odd) .time {
    position: static;
    text-align: left;
    padding-left: 5px;
    box-shadow: 0px 0px 0px 0px;
  }
  .timeline ul li:nth-child(even) .time {
    position: static;
    text-align: left;
    padding-left: 5px;
    box-shadow: 0px 0px 0px 0px;
  }
}

.experience-section {
  background-color: #f3f3f3;
}

.hiringPage-subheading {
  color: white;
  margin: auto;
  font-weight: 500;
}

.hireButton {
  font-size: x-large;
  font-weight: 700;
  text-decoration: none;
  color: #000000;
}

.collab {
  background-color: #000000;
}

.circle {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.circle .fas {
  color: #fff;
  animation: bounce 1.5s linear infinite;
}
@keyframes bounce {
  0% {
    transform: translateY(-25px);
    text-shadow: 0 0 20px #fff;
  }
  20% {
    transform: translateY(0px);
  }
  40% {
    transform: translateY(20px);
  }
  60% {
    transform: translateY(0px);
  }
  80% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(100px);
  }
}

.projectPage {
  background-color: aliceblue;
}

.Project-heading {
  color: black;
  font-size: 48px;
  font-weight: 600;
}

.project-subheading {
  font-weight: 600;
  font-size: x-large;
  color: #424242;
}

.aboutPage {
  background-color: aliceblue;
}

.about-avatar {
  border-radius: 50%;
}

.skillcard {
  box-sizing: border-box;
  height: auto;
  width: calc(100% / 4);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  margin: 20px;
  border-radius: 10px;
}
.skillcard .header {
  height: auto;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  background: white;
  color: coral;
  /* font-size: 12px;  */
  /* clip-path: circle(60% at 0% 0%); */
  /* transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1); */
}
.skillcard .header h4 {
  opacity: 1;
  transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.skillcard:hover .header {
  /* clip-path: circle(100% at 50% 50%); */
  /* background: salmon; */
}
.skillcard:hover .header h4 {
  opacity: 1;
}
.skillcard .header h3,
.skillcard .header h4 {
  margin: 0 !important;
  padding: 0;
}
.skillcard .description {
  padding: 20px;
}
.skillcards-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.web-icon {
  max-height: 6rem;
  max-width: 6rem;
}

.servicecards-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.servicecard {
  box-sizing: border-box;
  height: auto;
  width: calc(100% / 4);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  margin: 20px;
  border-radius: 10px;
}
.servicecard .header {
  height: auto;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  background: coral;
  color: white;
  font-size: 25px;
  clip-path: circle(60% at 0% 0%);
  transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.servicecard .header h4 {
  opacity: 0;
  transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.servicecard:hover .header {
  clip-path: circle(100% at 50% 50%);
  background: salmon;
}
.servicecard:hover .header h4 {
  opacity: 1;
}
.servicecard .header h3,
.servicecard .header h4 {
  margin: 0 !important;
  padding: 0;
}
.servicecard .description {
  padding: 20px;
}

.projectcards-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.projectcard {
  box-sizing: border-box;
  height: auto;
  width: 40%;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  margin-left: 100px;
  margin-top: 20px;
  border-radius: 10px;
}
.projectcard .header {
  height: auto;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  background: coral;
  color: white;
  font-family: 'Playfair Display', serif;
  font-size: 25px;
  clip-path: circle(60% at 0% 0%);
  transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.projectcard .header h5 {
  opacity: 0;
  transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.projectcard .header h6 {
  opacity: 0;
  color: #000000;
  font-weight: 700;
  transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.projectcard:hover .header {
  clip-path: circle(100% at 50% 50%);
  background: coral;
}
.projectcard:hover .header h5,
.projectcard:hover .header h6 {
  opacity: 1;
}
.projectcard .header h3,
.projectcard .header h4 {
  margin: 0 !important;
  padding: 0;
  color: #000000;
  font-weight: 600;
  font-size: larger;
}
.projectcard .description {
  padding: 20px;
  font-family: 'Nunito', sans-serif;
}

/* dual colors */
.projectcard.dual .header {
  background: coral;
}
.projectcard.dual .description {
  clip-path: circle(20% at 100% 100%);
  transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.5s;
  background: #1e3d59;
  height: auto;
  transition: all ease-in-out 0.7s;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  color: white;
}
.projectcard.dual:hover .description {
  clip-path: circle(100% at 50% 0%);
  background: #f5aa09;
}
.projectcard.dual:hover .header {
  clip-path: circle(100% at 50% 50%);
  background: #f5aa09;
}
@media (max-width: 768px) {
  .projectcards-wrapper {
    flex-direction: column;
  }
  .projectcards {
    width: 100%;
  }
}

.serviceInfo {
  color: whitesmoke;
  font-size: large;
}

@media (max-width: 768px) {
  .servicecards-wrapper {
    flex-direction: column;
  }
  .servicecard {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .skillcards-wrapper {
    flex-direction: column;
  }
  .card {
    width: 100%;
  }
}

/* Mobile devices */

@media only screen and (max-width: 600px) {
  .landingPage-name {
    color: whitesmoke;
    font-size: 38px;
    font-weight: 400;
  }
  .landingPage-avatar {
    border-radius: 50%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 10rem;
    height: 10rem;
  }
  .avatar {
    padding-top: 2rem;
  }
  .about-avatar {
    width: 20rem;
    height: 20rem;
    margin-left: 1rem;
  }
  .downArrows {
    display: none;
  }
  .skillcard {
    box-sizing: border-box;
    height: auto;
    width: 80%;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    margin: 20px;
    border-radius: 10px;
  }
  .resumeButton {
    margin-left: 3rem;
  }

  .projectcard {
    box-sizing: border-box;
    height: auto;
    width: 100%;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    margin-left: 1rem;
    margin-top: 1rem;
    border-radius: 10px;
  }
  .experience-title {
    textdecoration: 'none';
    color: #f5aa09;
  }
}
